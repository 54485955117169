@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.csnackbar {
  padding: 0px 12px!important;
  box-shadow: none!important;
}

.noWrap2 {
    display: 'block';
    display: -webkit-box;
    line-height: 1.5;
	overflow: hidden;
	position: relative;
	text-overflow: ellipsis;
    -webkit-line-clamp: 2;
  	-webkit-box-orient: vertical;
}

.leftShadowTable {
  box-shadow: -9px 0px 12px -1px rgba(0,0,0,0.07);
  -webkit-box-shadow: -9px 0px 12px -1px rgba(0,0,0,0.07);
  -moz-box-shadow: -9px 0px 12px -1px rgba(0,0,0,0.07);
}

.rightShadowTable {
  box-shadow: 12px 0px 12px -1px rgba(0,0,0,0.07)!important;
  -webkit-box-shadow: 12px 0px 12px -1px rgba(0,0,0,0.07)!important;
  -moz-box-shadow: 12px 0px 12px -1px rgba(0,0,0,0.07)!important;
  background-color: #fff;
}

.removeShadowTable {
  transition: linear .3s;
  box-shadow: none;
}

.cloudSync {
  animation: rotation 2s infinite linear;
  font-size: 20px;
}

@keyframes rotation {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}
 
.button-panel-venta {
  height: 100%;
  text-transform: none;
  background-color: #fff!important;
}
.icon-btn-primary { 
  color: rgba(149, 117, 205)!important;
  font-size: 40px;
}
.icon-btn-secondary { 
  color: rgba(121, 134, 203)!important;
  font-size: 40px;
}
.icon-btn-nota { 
  color: rgba(255, 0, 0, 0.3)!important;
  font-size: 40px;
}
.b-primary {
  border-color: rgb(149, 117, 205, 0.3)!important;
}
.b-secondary {
  border-color: rgb(121, 134, 203, 0.3)!important;
}
.b-nota {
  border-color: rgb(255, 0, 0, 0.3)!important;
}
.text-btn-panel {
  font-weight: 500!important;
  text-transform: none;
}
.text-btn-panel-sec {
  font-size: 14px!important;
  color: #a3a3a3;
  text-transform: none;
}

/* Scroll categorias */
.scroll-container {
  max-width: 1300px; /* Puedes ajustar el ancho según tus necesidades */
  overflow-x: auto; /* Asegura el scroll horizontal */
  overflow-y: hidden; /* Oculta el scroll vertical */
  white-space: nowrap; /* Evita que los elementos dentro se dividan en varias líneas */
  -ms-overflow-style: none; /* Desactiva la barra de scroll en IE y Edge */
  scrollbar-width: none; /* Desactiva la barra de scroll en Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Oculta la barra de scroll en navegadores WebKit (Safari, Chrome, etc.) */
}

.scroll-content {
  display: inline-block; /* Permite que los elementos dentro se muestren en línea horizontalmente */
  white-space: nowrap; /* Asegura que los elementos no se dividan en varias líneas */
}
